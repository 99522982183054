.container {
  .cropContent {
    display: flex;
    width: 100%;

    .imageCropper {
      flex: 1;
      position: relative;
      height: 90%;
      min-height: 30vh;
      margin-left: 2rem;
      justify-content: center;
      align-items: center;
      overflow: auto;
    }
  }
  .crop {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 2rem;
  }
}
