@import '../../styles/app';

.root {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
  .controlButton {
    height: fit-content;
  }

  .stepper {
    justify-content: space-around;
    align-items: center;
    flex: 1;
    padding: 0 2rem;

    .navLink {
      text-align: center;
      padding: 0.4rem 0;
      cursor: initial;
      user-select: none;

      &.activeStep {
        color: $navbar-link-active-color;
        border-bottom: 1px solid $navbar-link-active-color;
      }
    }

    .splash {
      height: 1px;
      border-top: 1px solid $divider-color;
      width: 10%;
    }
  }
}
