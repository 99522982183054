.canvas-player {
  height: 100%;
  align-items: baseline;
  margin: 0 auto;
  position: relative;
}

.webform-select-container {
  display: flex;
}

.player_container {
  width: fit-content;
  position: relative;
}

.canvas {
  width: 100%;
  max-height: 100%;
  display: flex;
  margin: 0 auto;
  border-radius: 5px;
}

.background-image {
  display: none;
  object-fit: cover;
}

.spinner {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.palyer-controls {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  bottom: 10px;
}

.player-options-container {
  margin-top: 5px;
}

.preview-wrapper {
  height: 100%;
  .preview-container {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;

    .toggle-play-button {
      box-sizing: border-box;
      height: 74px;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: transparent !important;
      padding: 0;
      border-color: transparent transparent transparent #1f1f1fca !important;
      transition: 100ms all ease;
      will-change: border-width;
      cursor: pointer;

      // play state
      border-style: solid;
      border-width: 37px 0 37px 60px;

      // paused state
      &.pause {
        border-style: double;
        border-width: 0px 0 0px 60px;
      }
    }

    .spinner-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.create-video-button {
  margin-top: 10px;
}

.completed-video {
  display: flex;
  margin: 0 auto;
  outline: none;
}
