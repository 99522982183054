.container {
  display: flex;
  flex-direction: column;
  .item {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
