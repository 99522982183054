@import '../../../styles/app';

.root {
  height: 100%;

  .audioSelectContainer {
    display: flex;
    width: fit-content;
    .preview {
      display: flex;
      justify-content: space-around;
      & > span {
        color: white;
      }
    }

    .fileBrowser {
      overflow: hidden;
      border: 1px solid $teal;
      border-radius: 2rem;
      width: 100%;
      height: 622px;
      max-width: 537px;
      padding: 4rem 3.5rem;

      .inputLabel {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }

      .dropContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .uploadIcon {
          transform: translateX(11px);
        }
        .selectButton {
          padding: 9px 36px 11px 35px;
          border-radius: 10px;
          background-color: #546176;
          letter-spacing: -0.6px;
          font-size: 15px;
          font-weight: 500;
          color: white;
        }
        .uploadAudio {
          margin-top: 41px;
          margin-bottom: 15px;
          font-size: 30px;
          font-weight: 900;
          color: white;
          white-space: nowrap;
        }
        .dragDropLabel {
          opacity: 0.48;
          font-size: 15px;
          font-weight: 500;
          margin-top: 20px;
          color: rgba(255, 255, 255, 0.48);
        }
      }
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    flex: 1;
    .column {
      padding: 0;
      display: flex;
      flex-direction: column;
      color: white;
      &.error {
        color: $red;
      }
    }
    .caption {
      color: $primary;
      font-size: 25px;
    }

    .detail {
      font-size: 18px;
      font-weight: 500;
    }

    .instruction {
      margin: 0;
      margin-top: 3.5rem;
      margin-bottom: 1.5rem;
      font-size: 30px;
      font-weight: 900;
    }

    .sizeDescription {
      display: flex;
      flex-direction: column;
      margin: 3.5rem 0;
      padding: 0;
      color: white;

      &.error {
        color: $red;
      }
    }

    .allTracks {
      width: fit-content;
    }

    .examplesContainer {
      margin: 0;
      margin-top: 2rem;
    }
  }
}

.previewRoot {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
