@import '../../../../styles/app.scss';

.root {
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-right: 1rem;
  align-items: center;

  .thumbContainer {
    position: relative;

    .thumbnail {
      width: 185px !important;
      height: 132px;
      border-radius: 20px;
    }
    .playButton {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 50%;

      i {
        font-size: 3rem;
      }
    }
  }
}
