@import '../../../styles/app';

.root {
  flex-direction: column;
  .videoTitle {
    font-size: 2rem;
    font-weight: 900;
    width: 25rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    border-bottom: 2px solid white;
    border-radius: 0;
    padding: 1rem 0;
  }
  .createVideoContent {
    margin-top: 43px;
    .previewContainer {
      display: flex;
      flex-direction: column;
      padding: 0;
      .previewWrapper {
        .video {
          min-height: 240px;
          width: 100%;
          background-color: black;
          border-radius: 1rem;
        }
      }
    }
    .content {
      padding-left: 6rem;
      .buttonIcon {
        padding-right: 0.5rem;
      }
      .buttonsRow {
        margin-bottom: 1rem;
        .socialButton {
          width: 11rem;
          margin-right: 1.2rem;
          background-color: #313946;
          border: none;
        }
      }
      .share {
        padding-bottom: 2rem;
        font-size: 30px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        max-width: 366px;
      }
      .title {
        padding: 2rem 0;
        font-size: 30px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        max-width: 366px;
      }
      .downloadButton {
        color: white;
        &:active {
          color: white;
        }
      }
    }
  }
}
