.root {
  .wavforms {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 20px 0;
    max-width: 100%;
    width: 100%;
    overflow-x: auto;
  }

  .title {
    display: flex;
  }
}
