.container {
  display: flex;
  justify-content: center;
  column-gap: 1rem;

  .title {
    font-size: 0.8rem;
    &.portrait {
      writing-mode: vertical-rl;
      text-orientation: upright;
    }
  }
}
