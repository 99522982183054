html {
  font-weight: 100;
  font-size: $font-size-initial;
}

body {
  overflow-x: hidden;
  color: $text-color;
  background: $bg-custom-dark;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

html,
body,
#app {
  height: 100%;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma'),
    url('../fonts/axiforma/Axiforma-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

a,
.dropdown-item,
.btn {
  cursor: pointer;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out,
    border-color 0.1s ease-in-out;
}

.divider-vertical {
  height: 0px;
  width: 100%;
  border-top: 1px solid $divider-color;
}

.divider-horizontal {
  height: 100%;
  width: 0px;
  border-left: 1px solid $divider-color;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

th {
  font-weight: 600;
}

td {
  font-weight: 300;
}

label {
  font-weight: 400;
}

.row {
  margin: 0;
}

strong {
  font-weight: 700;
}

ul ul,
ol ul {
  list-style: none;
}

ul,
ol {
  list-style: none;
  padding-left: 0;
}
