@import "../../../styles/app";

.root {
  position: relative;
  width: 252px;
  height: 183px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 1.2rem;
  margin: 2.5rem auto;
  overflow: hidden;
  background-color: $gray;
  user-select: none;
  cursor: pointer;

  .content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-12px);
    margin: 0;

    .folder {
      width: 103px;
      pointer-events: none;
    }

    .icon {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 50%;
      width: 2rem;
      height: 2rem;
      background-color: $blue;
      border-radius: 50%;
      transform: translate(100%, -120%);
    }

    .playIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -20%);
      color: black;
    }
  }

  .startCaption {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: $padding-small-vertical;
    display: flex;
    justify-content: center;
    border-top: 1px solid $sidebar-bg-color;
    font-size: $font-size-larger;
    font-weight: $font-weight-bolder;
  }
}
