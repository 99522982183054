@import '../../../styles/app';

.root {
  & > label {
    margin: 0;
  }
  .videoTitle {
    font-size: 2rem;
    font-weight: 900;
    width: 25rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    border-bottom: 2px solid white;
    border-radius: 0;
    padding: 1rem 0;
  }
  .content {
    column-gap: 6rem;
    row-gap: 2rem;
    margin-top: 2.625rem;
    width: 100%;
    display: flex;

    .imageContainer {
      position: relative;
      flex: 1;
    }
    .descriptionContent {
      min-width: 18rem;
      max-width: 30vw;
      overflow-y: auto;
      overflow-x: hidden;
      .imageLoader {
        .imageInput {
          display: none;
        }
        .uploadButton {
          margin-right: 1rem;
          color: white;

          &:active {
            color: white;
          }
        }
      }
      .captionLabel {
        color: white;
        padding: 32px 0;
        font-size: 30px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.1;
      }
      .captionWrapper {
        display: flex;
        flex-direction: column;
        .caption {
          max-width: 380px;
          border-radius: 10px;
          border: solid 1px #ffffff;
          padding: 1rem 2rem;
        }
      }
      .captionLocation {
        display: flex;
        align-items: center;

        .locationLabel {
          font-size: 20px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.15;
        }

        .options {
          display: flex;
          align-items: center;
          padding: 0 0.5rem;
          margin: 0;

          & > li {
            padding: 0 0.5rem;
            font-size: 15px;
            line-height: 2.4;
            cursor: pointer;
            user-select: none;
            color: #ffffff;

            &:hover {
              color: $primary;
            }
            &.active {
              color: $primary;
            }
          }
        }
      }
    }
  }
  .createVideoLayer {
    margin-top: 2rem;
  }
}

.modal {
  .modalBody {
    position: relative;
    border-top: 1px solid $gray-900;

    .exampleBackgrounds {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      .rounded {
        border-radius: 0.8rem;
      }
    }
  }
}
